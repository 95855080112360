/* import * as Sentry from '@sentry/vue' */
import assets from '~/config/assets'

/* Main Site Config */
import constants from '~/config/constants'
import { variantConfig } from '~/config/variant'

export default defineNuxtPlugin(() => {
    const { public: config } = useRuntimeConfig()

    const lang = variantConfig.siteLangs[config.variant as 'megadescuentos' | 'jdescuentos']

    const openCoupon = (slug: string, storeSlug = '', landing = lang.routes.brands) => {
        if (storeSlug) {
            window.open(
                `${process.env.NODE_ENV === 'production' ? config.origin : location.origin}/${config.variant !== 'cupohosting' ? `${landing}/` : landing === 'landing' ? `${landing}/` : ''}${storeSlug}?slug=${slug}`,
                '_blank',
            )
            location.href = `${config.isProduction ? config.origin : location.origin}/open/${slug}`
        } else {
            window.open(`${config.isProduction ? config.origin : location.origin}/open/${slug}`, '_blank')
        }
    }

    const goToOffer = (id: number) => {
        window.open(`${config.isProduction ? config.origin : location.origin}/go/${id}`, '_blank')
    }

    const openOffer = (id: number, slug?: string, storeSlug = '') => {
        if (storeSlug) {
            window.open(
                `${config.isProduction ? config.origin : location.origin}/${lang.routes.brands}/${storeSlug}?slug=${slug}&type=offer`,
                '_blank',
            )
            location.href = `${config.isProduction ? config.origin : location.origin}/go/${id}`
        } else {
            window.open(`${config.isProduction ? config.origin : location.origin}/go/${id}`, '_blank')
        }
    }

    const openDiscount = (payload: {
        id: number
        slug: string
        type: 'offer' | 'coupon'
        storeSlug?: string
        landing?: string
    }) => {
        const originResolved = config.isProduction ? config.origin : location.origin

        const landingResolved = payload.landing || lang.routes.brands

        const maskRoute = payload.type === 'coupon' ? 'open' : 'go'

        if (payload.storeSlug) {
            window.open(
                `${originResolved}${config.variant === 'cupohosting' ? '' : `/${landingResolved}`}/${payload.storeSlug}?id=${payload.id}&type=${payload.type}`,
                '_blank',
            )
            location.href = `${originResolved}/${maskRoute}/${/* payload.type === 'coupon' ? payload.slug : */ payload.id}`
        } else {
            window.open(
                `${originResolved}/${maskRoute}/${/* payload.type === 'coupon' ? payload.slug : */ payload.id}`,
                '_blank',
            )
        }
    }

    return {
        provide: {
            assets: assets(config.variant),
            lang,
            constants,
            openers: {
                openDiscount,
                openOffer,
                openCoupon,
                goToOffer,
            },
        },
    }
})
