import { ofetch } from 'ofetch'

export default defineNuxtPlugin((_nuxtApp) => {
    globalThis.$fetch = ofetch.create({
        onRequest({ request, options }) {
            if (import.meta.server) {
                const clientIp = _nuxtApp.ssrContext?.event.node.req.headers['x-forwarded-client-ip'] as
                    | string
                    | undefined

                if (clientIp) {
                    options.headers.append('X-SSR-Request', 'true')
                    options.headers.append('X-SSR-Client-IP', clientIp)
                }
            }
        },
    })
})
