import type { RouterConfig } from '@nuxt/schema'
import { variantConfig } from '~/config/variant'

const LANG = variantConfig.siteLangs[variantConfig.siteVariant]

export default <RouterConfig>{
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        if (to.path !== from.path) {
            return { top: 0 }
        }
    },
}
