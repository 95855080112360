<template>
    <NuxtLayout name="default">
        <div class="error-container">
            <div class="error-container__details">
                <img
                    :src="`/assets/svg/${error.statusCode}.svg`"
                    alt="error code"
                    class="error-container__image"
                />
                <div class="relative z-10">
                    <img class="mx-auto mb-6 block h-20" src="/assets/png/Surprise-Emoji.png" alt="" />
                    <h1>{{ texts[error.statusCode].title }}</h1>

                    <h2 v-if="texts[error.statusCode].message">{{ texts[error.statusCode].message }}</h2>

                    <div class="error-container__button">
                        <button
                            v-if="backurl && backurlAvailable"
                            class="error-container__button-back"
                            @click="goback"
                        >
                            {{ $lang.components.layoutError.go_back }}
                        </button>
                        <NuxtLink no-prefetch to="/" class="error-container__button-base" rel="follow">
                            {{ $lang.components.layoutError.go_home }}
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </div>
    </NuxtLayout>
</template>

<script lang="ts" setup>
import { useRootStore } from './store/root'

const props = defineProps({
    error: {
        type: Object as PropType<{ statusCode: 500 | 401 | 410 | 404 | 403; message: string }>,
        required: true,
    },
})

const backurl = ref(null) as Ref<null | History>
const backurlAvailable = ref(false)

onMounted(() => {
    backurl.value = window.history
    backurlAvailable.value = backurl.value && backurl.value.length > 1
})

const { $lang } = useNuxtApp()

const title = $lang.components.layoutError.title_seo
const description = $lang.components.layoutError.description_seo

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

const texts = {
    404: $lang.components.layoutError.e404,
    403: $lang.components.layoutError.e403,
    500: $lang.components.layoutError.e500,
    410: $lang.components.layoutError.e410,
    401: $lang.components.layoutError.e401,
}

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
const RootStore = useRootStore()
const goback = () => {
    if (props.error.statusCode === 410) {
        clearError()
    } else if (backurl.value) {
        backurl.value.back()
    }
}
</script>

<style lang="postcss" scoped>
.error-container {
    @apply relative bg-cover p-4;
    h1 {
        @apply mb-2 text-center text-3xl font-semibold;
    }
    h2 {
        @apply mb-2 text-center text-lg text-gray-600;
    }
    &__details {
        @apply relative mx-auto flex flex-col items-center py-32;
    }
    &__image {
        @apply absolute left-1/2 top-0 h-full w-full max-w-[768px] -translate-x-1/2 transform object-contain object-[50%,20%];
    }
    &__button {
        @apply z-50 mx-auto flex max-w-72 flex-col gap-3;
        &-back {
            @apply justify-center rounded-lg bg-site-primary px-8 py-3 text-site-primary-text-contrast hover:bg-site-primary-hover;
        }
        &-base {
            @apply z-50 flex justify-center gap-2 rounded-lg border-2 border-site-primary bg-white bg-opacity-30 px-8 py-3;
            img {
                @apply w-4 self-center;
            }
        }
    }

    &__cone {
        @apply absolute bottom-2 right-2 z-10 w-20 md:bottom-10 md:right-28 md:w-max lg:bottom-3 lg:right-3;
    }
}
</style>
