import general from './general'
import info from './info'
import messages from './messages'
import notifications from './notifications'
import user from './user'
import pages from './pages'

export default {
    general,
    info,
    messages,
    notifications,
    user,
    pages,
}
