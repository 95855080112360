import revive_payload_client_4sVQNw7RlN from "/home/runner/work/deals-community-front/deals-community-front/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/deals-community-front/deals-community-front/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/deals-community-front/deals-community-front/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/runner/work/deals-community-front/deals-community-front/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/runner/work/deals-community-front/deals-community-front/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/deals-community-front/deals-community-front/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/deals-community-front/deals-community-front/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/deals-community-front/deals-community-front/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/deals-community-front/deals-community-front/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/deals-community-front/deals-community-front/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/deals-community-front/deals-community-front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_6wEQMY3tee from "/home/runner/work/deals-community-front/deals-community-front/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import Sentry_Cv1kVnXGK7 from "/home/runner/work/deals-community-front/deals-community-front/plugins/Sentry.ts";
import addSiteConfig_DqQq9IxWb8 from "/home/runner/work/deals-community-front/deals-community-front/plugins/addSiteConfig.ts";
import breakpoints_client_Hou90xhv99 from "/home/runner/work/deals-community-front/deals-community-front/plugins/breakpoints.client.ts";
import dynamic_assets_client_veoFLQqk7J from "/home/runner/work/deals-community-front/deals-community-front/plugins/dynamic-assets.client.ts";
import ofetch_TmnSnS1Swe from "/home/runner/work/deals-community-front/deals-community-front/plugins/ofetch.ts";
import scroll_observer_client_01NZAM9PtQ from "/home/runner/work/deals-community-front/deals-community-front/plugins/scroll-observer.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_6wEQMY3tee,
  Sentry_Cv1kVnXGK7,
  addSiteConfig_DqQq9IxWb8,
  breakpoints_client_Hou90xhv99,
  dynamic_assets_client_veoFLQqk7J,
  ofetch_TmnSnS1Swe,
  scroll_observer_client_01NZAM9PtQ
]