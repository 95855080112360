import { defineStore } from 'pinia'
import type { NotificationOptions } from '~/components/header/HeaderNotifications.vue'
import type { DropdownOptionType } from '~/components/dropdowns/Options.vue'
import type { Models } from '~/types/models'
import type { Api } from '~~/global'

type GenericModal =
    | {
          type: 'confirm'
          info: {
              text: string
              continue: {
                  handler: () => any
                  text: string
              }
              cancel: {
                  handler: () => any
                  text: string
              }
          }
      }
    | {
          type: 'message'
          info: {
              refresh: (id: null | number) => void
          }
      }
type DashboardModal = {
    type: 'email' | 'password' | 'username'
    info: {
        callback: (success: boolean) => void
    }
}

export const useRootStore = defineStore('RootStore', () => {
    const layoutData = ref(null) as Ref<null | Api.Responses.General>
    const headbandData = ref(null) as Ref<null | Api.Responses.SiteHeadband>
    const siteNotification = ref(null) as Ref<null | NotificationOptions>
    const loading = ref(false) as Ref<boolean>
    const discountModal = ref(null) as Ref<null | Models.Discount>
    const siteDropdown = ref(null) as Ref<null | DropdownOptionType[]>
    const genericModal = ref(null) as Ref<null | GenericModal>
    const dashboardModal = ref(null) as Ref<null | DashboardModal>

    const setLoadingStatus = (status: boolean) => {
        loading.value = status
        loading.value = status
    }

    const setGenericModal = (modal: GenericModal | null) => {
        genericModal.value = modal
    }
    const setDashboardModal = (modal: DashboardModal | null) => {
        dashboardModal.value = modal
    }

    const getNotification = computed(() => siteNotification)

    const isLoading = computed(() => loading)

    const setLayoutData = (data: Api.Responses.General) => {
        layoutData.value = data
    }
    const setDiscountModal = (data: Models.Discount | null) => {
        discountModal.value = data
    }
    const setHeadbandData = (data: Api.Responses.SiteHeadband) => {
        headbandData.value = data
    }
    const setSiteNotification = (data: NotificationOptions | null) => {
        if (data && data.text.length > 5) {
            siteNotification.value = data
        } else {
            siteNotification.value = null
        }
    }
    const setSiteDropdown = (data: DropdownOptionType[] | null) => {
        siteDropdown.value = data
    }

    return {
        layoutData,
        siteNotification,
        headbandData,
        getNotification,
        isLoading,
        discountModal,
        genericModal,
        dashboardModal,
        siteDropdown: computed(() => siteDropdown),
        setGenericModal,
        setDashboardModal,
        setLayoutData,
        setSiteDropdown,
        setHeadbandData,
        setLoadingStatus,
        setSiteNotification,
        setDiscountModal,
    }
})
