import { variantConfig } from '~/config/variant'
import { useRootStore } from '~/store/root'

import type { Api } from '~~/global'

export default defineNuxtRouteMiddleware(async (to) => {
    if (to.path !== '/' && to.path.at(-1) === '/') {
        return navigateTo(
            {
                ...to,
                path: to.path.slice(0, -1),
            },
            {
                redirectCode: 301,
            },
        )
    }

    const { $lang, $config } = useNuxtApp()

    await callOnce(async () => {
        const RootStore = useRootStore()

        const {
            buildHeaders,
            baseURL,
            endpoints: {
                general: { general },
            },
        } = useApiConfig()

        const headers = buildHeaders()

        const layoutData = await $fetch<Api.Responses.General>(general.path, {
            method: 'GET',
            headers,
            baseURL,
        }).catch((err) => console.log('General data fetch: ', err.data))

        const siteHeadband = await $fetch<Api.Responses.SiteHeadband>(general.links_headband, {
            method: 'GET',
            headers,
            baseURL,
        }).catch((err) => console.log('General headband fetch: ', err.data))

        if (layoutData) RootStore.setLayoutData(layoutData)

        if (siteHeadband) RootStore.setHeadbandData(siteHeadband)
    })

    if (
        to.path.includes(`/${$lang.routes.forum}`, 0) ||
        to.path.includes(`/${$lang.routes.categories}`, 0) ||
        to.path.includes(`/${$lang.routes.share}`, 0) ||
        variantConfig.deletedPages[$config.public.variant as 'megadescuentos'].find((page) => {
            return to.path === page
        })
    ) {
        throw showError({
            statusCode: 410,
            message: 'Page deleted (gone)',
        })
    }

    const { buildHeaders, baseURL, endpoints } = useApiConfig()

    const redirect = await $fetch<Api.Responses.Redirect>(
        endpoints.general.redirects.path.replace('_FROM_', to.path),
        {
            method: 'GET',
            headers: buildHeaders(),
            baseURL,
        },
    ).catch<null | { status_code: 410; to: null }>((e) => {
        if (e.statusCode !== 404) return { status_code: e.statusCode, to: null }
        else return null
    })

    if (redirect?.to && redirect?.status_code === 301) {
        return navigateTo(redirect.to, { redirectCode: redirect.status_code })
    }

    if (redirect?.status_code && redirect.status_code === 410) {
        throw showError({
            statusCode: redirect?.status_code,
            message: 'Page deleted (gone)',
        })
    }
})
