const ES = {
    brand: 'Bchollos',
    symbol: '€',
    routes: {
        subscribe: 'subscribe',
        unsubscribe: 'unsubscribe',
        activity: 'actividad',
        awards: 'premios',
        blog: 'blog',
        brands: 'codigos-descuento',
        categories: 'descuentos',
        chat: 'chat',
        chats: 'chats',
        coins: 'monedas',
        commented: 'comentados',
        contact: 'contacto',
        coupon: 'cupon',
        coupons: 'cupones',
        dashboard: 'dashboard',
        discounts: 'descuentos',
        events: 'eventos',
        changePassword: 'cambiar-contrasena',
        forgotPassword: 'olvide-mi-contrasena',
        forum: 'foro',
        free: 'gratis',
        inbox: 'mensajeria',
        login: 'login',
        lotteries: 'sorteos',
        lottery: 'sorteo',
        news: 'nuevos',
        notifications: 'notificaciones',
        novelties: 'novedades',
        offer: 'chollo',
        offers: 'chollo',
        register: 'registro',
        rewards: 'premios',
        saved: 'guardados',
        settings: 'ajustes',
        share: 'compartir',
        create: 'crear',
        preferences: 'preferencias',
        recovery: 'cambiar-contrasena',
        successChangedPassword: 'contrasena-actualizada-exitosamente',
        expired: 'expirados',
        extension: 'extension',
        extensionUninstalled: 'extension-desinstalada',
        ended: 'finalizado',
        verifyAccount: 'verificar-cuenta',
        search: 'search',
        newPassword: 'nueva-contrasena',
        loginWithoutPass: 'inicio-sin-contrasena',
        rules: 'reglas',
        help: 'ayuda',
        about_us: 'acerca-de-nosotros',
        terms: 'aviso-legal',
        privacy: 'politica-de-privacidad',
        cookies: 'cookies',
        legal: 'aviso-legal',
        press: 'media',
        faq: 'faq',
        top25: 'top-25-descuentos',
        sendCoupon: 'enviar-cupon',
        authors: 'autores',
    },
    components: {
        ctaEMail: {
            title: 'Recibe todos los días los',
            title_2: 'mejores descuentos',
            title_3: 'en tu correo',
            subtitle: '¡Cada día nuevas ofertas & cupones',
            subtitle_2: '100% gratis!',
            placeholder: 'Ingresa tu correo electrónico aquí..',
            button: 'Registrarme',
            success: 'Email suscrito correctamente.',
            failed: 'El email ya se encuentra suscrito.',
            error: 'Ocurrio un error',
            insertEmail: 'Ingrese un correo electrónico',
        },
        ctaExtAndApp: {
            url: 'https://chrome.google.com/webstore/detail/bchollos/gkochegofmljpmgegphbcljdigenjlhd?hl=es&authuser=5',
            extHead: 'Instala gratis nuestra',
            extTitle: 'extensión',
            extDescription: 'Buscador automático de cupones y ofertas',
            appHead: 'Descarga',
            appTitle: 'nuestra app',
        },
        ctaSocial: {
            notification: 'Notificación',
            notification_text: 'Recibe CHOLLOS gratis y actualizados en',
            notification_text_2: {
                facebook: 'Facebook?',
                telegram: 'Telegram?',
                whatsapp: 'Whatsapp?',
            },
            team: 'Equipo Bchollos',
            accept: '¡Sí, acepto!',
            url: {
                telegram: 'https://t.me/bchollos',
                facebook: 'https://www.facebook.com/groups/710058077386583',
                whatsapp: 'https://chat.whatsapp.com/CnJYHYTDn4mCOSMV8hTVKJ',
            },
        },
        bannerHomepage: {
            welcome: 'Bienvenido a Bchollos, soy Jotah',
            signup: 'Regístrate gratis y consigue muchas sorpresas',
        },
        sidebarUserRanking: {
            rank: 'Ranking',
            weekly: 'Semanal',
            monthly: 'Mensual',
            comments: 'Comentarios',
            discounts: 'Descuentos',
        },
        microArcLinks: {
            discounts: 'Descuentos',
        },
        sidebarBanners: {
            publicity: 'Ad',
        },
        cardMainGlobalFeatured: {
            published_by: 'Por',
            ago: 'Hace',
            offer_big: 'CHOLLO',
            coupon_big: 'CUPÓN',
            more: 'Mas de',
            okay: 'De acuerdo',
            free_ship: 'ENVÍO GRATIS',
            free: 'GRATIS',
            offers: 'chollos',
            offer: 'Chollo',
            coupon: 'Cupon',
            save: 'guardar',
            delete: 'eliminar',
            saved: 'guardado',
            saved_a: 'guardada',
            deleted: 'eliminado',
            deleted_a: 'eliminada',
            something_happens: 'ocurre algo con',
            error_on: 'Error al',
            this_offer: 'este chollo',
            this_coupon: 'este cupón',
            error_on_save: 'Error al guardar, verifica el estado de tu sesión',
        },
        bannerGeneric: {
            signup_google: 'Iniciar con Google',
            signup_facebook: 'Iniciar con Facebook',
            follow: 'Seguir',
            earn_prizes: '¡Gana premios y regalos participando!',
            register_earn_coins: 'Regístrate gratis hoy y gana monedas',
            register_earn_md: 'Regístrate gratis hoy y gana bchollos',
            follow_us: 'Síguenos para enterarte de chollos y códigos descuento',
            main_image_alt: 'icono de bchollos',
            coin_image_alt: 'icono de moneda',
        },
        btnDiscount: {
            copied: 'Copiado',
            see_coupon: 'Ver cupón',
            participate_lottery: 'Participar en el sorteo',
            see_offer: 'Ver Chollo',
            no_coupon_required: 'NO REQUIERE CUPÓN',
        },
        btnSeeMoreResults: {
            see_more: 'Ver más',
        },
        cardDashboardActivity: {
            time_ago: 'Hace',
            see_lottery: 'Ver sorteo',
            see_forum: 'Ver chat/foro',
            see_offer: 'Ver chollo',
            see_coupon: 'Ver cupón',
            unsupported: 'No soportado',
        },
        cardDashboardCoin: {
            coins: 'Monedas',
            see_coins: 'Ver monedas',
            swap: 'Canjear',
        },
        cardDashboardLottery: {
            end_at: 'Finaliza en',
            how_participate: '¿Cómo se participa?',
        },
        cardDashboardRouter: {
            end_at: 'Finaliza en {{data|time}}',
            back: 'Atrás',
        },
        cardMainCoupons: {
            offer: 'OFERTA',
            coupon: 'CUPÓN',
            verified: 'Verificado',
            exclusive: 'Exclusivo',
            today: 'HOY',
            tomorrow: 'MAÑANA',
            updated_at: 'Actualizado hace',
            free: 'GRATIS',
            free_ship: 'ENVÍO GRATIS',
            time_ago: 'Hace',
            end_at: 'Finaliza',
            local: 'Local',
            hide: 'Ocultar',
            show: 'Mostrar',
        },
        layoutFooter: {
            follow_in_telegram: 'Síguenos en Telegram para más descuentos',
            register_free: 'Regístrate gratis y recibe descuentos en tu email',
            write_email: 'Escribe tu email aquí...',
            subscribe: 'Suscribirse',
            enjoy: 'Más de 150 tiendas online y contamos con los mejores códigos promocionales en marcas como Temu, Samsung, AliExpress o Amazon.',
            first_of: 'sitio de cupones y códigos de descuento favorito en España 🇪🇸.',
            community: 'Bchollos es e',
            privacy_policy: 'Política de privacidad',
            terms_of_use: 'Términos de uso',
            cookies_policy: 'Política de cookies',
            successSubscription: 'Correo electrónico suscrito correctamente.',
            emailAlreadyExist: 'Este correo electrónico ya está suscrito..',
            errorOnSubscribing: 'Se ha producido un error.',
        },
        cardMainGlobal: {
            verified: 'Verificado',
            exclusive: 'Exclusivo',
            programmed: 'Programado',
            today: 'HOY',
            tomorrow: 'MAÑANA',
            updated_at: 'Actualizado hace',
            time_ago: 'Hace',
            end_at: 'Finaliza',
            local: 'Local',
            new: 'Nuevo',
            see_more: 'Ver más',
            start_at: 'Inicia',
            agreement: 'De acuerdo',
            free: 'GRATIS',
            free_ship: 'ENVÍO GRATIS',
            offers: 'ofertas',
            coupons: 'cupones',
            offer: 'Chollo',
            coupon: 'Cupon',
            save: 'guardar',
            delete: 'eliminar',
            saved: 'guardado',
            saved_a: 'guardada',
            deleted: 'eliminado',
            deleted_a: 'eliminada',
            something_happens: 'ocurre algo con',
            error_on: 'Error al',
            this_offer: 'este chollo',
            this_coupon: 'este cupón',
            error_on_save: 'Error al guardar, verifica el estado de tu sesión',
        },
        cardMainGlobalSkeleton: {
            new: 'Nuevo',
            see_more: 'Ver más',
            offers: 'chollos',
        },
        cardMainLottery: {
            how_participate: 'Como se participa:',
        },
        cardMainMini: {
            free: 'GRATIS',
            free_ship: 'ENVÍO GRATIS',
        },
        cardSharePreviewChat: {
            title: 'Este es el título del chat',
            select: 'Selecciona...',
            select_category: 'Selecciona una categoría',
            description: 'Aquí va la descripción',
        },
        cardSharePreviewLottery: {
            title: 'Aquí va el título de tu chat',
            select: 'Selecciona...',
            select_category: 'Categoría',
            description: 'Aquí va la descripción de tu sorteo y se irá completando poco a poco.',
            your_name: 'Tu nombre',
            lottery_image_seo: 'Imagen del sorteo',
            user_image_seo: 'Imagen de usuario',
        },
        cardSharePreviewOfferAndCoupon: {
            title: 'Aquí va el título de tu',
            new: 'Nuevo',
            description1: 'Aquí va la descripción de tu',
            description2: 'y se irá completando poco a poco.',
            information:
                'Información del sorteo como fechas, nº de veces que se puede participar y todos los detalles…',
            start_at: 'Inicia',
            end_at: 'Finaliza',
            free: 'GRATIS',
            free_ship: 'Envío gratis',
        },
        layoutHeaderSearch: {
            search: 'Buscar',
            must_min_2_chars: 'Debe introducir minimo 2 caracteres para una busqueda mas exacta.',
            okay: 'De acuerdo',
            error: 'Error de búsqueda',
        },
        microStepper: {
            share: 'Compartir',
            description: 'Descripción',
            finished: '¡Finalizado!',
        },
        cardShareStepOne: {
            share: 'Compartir',
            paste_link: 'Pega el link donde se puede usar el código o ver más información.',
            coupon_code: 'Código de cupón',
            no_link_needed: 'No se necesita link',
            start: '¡Empecemos!',
            share_offer: 'Compartir chollo',
            share_coupon: 'Compartir cupón',
            insert_valid_url:
                'Por favor ingresa una url válida o haz click en el botón "No se necesita link"',
            hide: 'Ocultar',
            image_not_found: 'No encontramos ninguna imagen en el enlace, pero puedes agregarlas manualmente',
            error: 'Sucedió un error inesperado, intente nuevamente en unos minutos',
        },
        cardShareStepTwo: {
            share: 'Compartir',
            share_coupon: '¿compartir un cupón?',
            share_offer: '¿compartir chollo?',
            share_an: 'Compartir una',
            share_a: 'Compartir un',
            link: 'Enlace',
            required: 'Obligatorio',
            optional: 'Opcional',
            error: 'Hay un error. Por favor verifica este campo.',
            images_from_link: 'Imágenes del link',
            upload_images_here: 'Subir imagen aquí',
            offer_details: 'Detalles del chollo (Opcional)',
            error_field: 'Hay un error. Por favor verifica este campo.',
            discount_type: 'Tipo de descuento',
            coupon_code: 'Código del cupón',
            coupon_to_use: 'Cupón a utilizar (Solo si es necesario)',
            title: 'Título',
            set_offer_details: 'Introduce detalles del chollo (tienda, descuento y lo que quieras)',
            max_140_chars: 'Máximo 140 carácteres',
            description: 'Descripción',
            category: 'Categoría',
            select_category: 'Selecciona la categoría que más se ajusta a tu oferta.',
            where_participate: 'Donde se participa',
            error_fields: 'Hay un error. Por favor verifica los campos obligatorios.',
            start_date: ' Fecha de inicio',
            end_date: 'Fecha de expiración',
            where_send: '¿Desde dónde se envía?',
            local_coupon: '¿Es un cupón local?',
            local_offer: '¿Es un chollo local de tienda física?',
            send: 'Enviar',
            preview: 'Vista previa',
            select: 'Selecciona...',
            website: 'Sitio Web',
            other: 'Otro',
            description_long:
                'En la descripción indica todos los detalles relevantes como precios, descuento, detalles adicionales, etc...',
            insert_valid_url: 'Por favor ingresa una url válida',
            hide: 'Ocultar',
            images_not_found:
                'No encontramos ninguna imagen en el enlace, pero puedes agregarlas manualmente',
            title_40_chars: 'El título debe ser mayor a 40 caracteres',
            default_image_no_removable:
                'No puedes eliminar la imagen por defecto, pero puedes subir o seleccionar otra',
            file_not_permitted: 'Archivo no permitido',
            size_exceeded: 'Archivo excedido de tamaño',
        },
        CardSliderEventFeaturedDiscounts: {
            discount_not_found: 'No encontramos ningún descuento destacado para este evento.',
            featured_discounts: 'Descuentos destacados',
        },
        CardSliderEventFeaturedStores: {
            store_not_found: 'No encontramos ninguna tienda destacada para este evento.',
            featured_stores: 'Tiendas destacadas',
            today: 'Hoy',
            day: 'Día',
            week: 'Semana',
            month: 'Mes',
            always: 'Siempre',
        },
        cardSliderFeaturedDiscounts: {
            featured_offers: 'Chollos destacados',
            today: 'Hoy',
            day: 'Día',
            week: 'Semana',
            month: 'Mes',
            always: 'Siempre',
        },
        cardBlog: {
            minutes: 'Minutos',
            like: 'Me gusta',
        },
        cardComment: {
            like: 'Me gusta',
            answer: 'Responder',
            responses: 'respuestas',
            copy_link_to_comment: 'Copiar enlace al comentario',
            report: 'Denunciar',
            why_report: '¿Por qué lo denuncias?',
            spam_offensive: 'Spam/Ofensivo',
            abuse_hate: 'Acoso o incitación al odio',
            okay: 'De acuerdo',
            report_success: 'Su reporte fue enviado exitosamente',
            report_failed: 'No se pudo realizar el reporte',
            answer_published: 'Respuesta publicado de manera exitosa',
            answer_pending: 'Respuesta pendiente de aprobación',
            close: 'Cerrar',
            hide: 'Ocultar',
        },
        cardCommentThread: {
            load_more_answers: 'Cargar respuestas anteriores',
        },
        cardEvent: {
            starts: 'Inicia:',
            ends: 'Finaliza:',
            ended_at: 'Finalizó el',
        },
        cardExtension: {
            now_easier: '¡Ahora lo tienes más fácil!',
            install_our_extension: 'Instala nuestra extensión',
            go_to_extension: 'Ir a la extensión',
        },
        cardFaq: {
            frequent_questions: 'Preguntas frecuentes',
            question: 'Question',
        },
        cardFeatured: {
            coupon: 'CUPÓN',
            offer: 'CHOLLO',
            free: 'GRATIS',
            free_ship: 'ENVÍO GRATIS',
        },
        cardFeaturedShop: {
            coupon: 'CUPÓN',
            offer: 'CHOLLO',
            free: 'GRATIS',
            free_ship: 'ENVÍO GRATIS',
        },
        cardNews: {
            created_at: 'Se creó el',
        },
        cardNotifications: {
            see_novelty: 'Ver novedad',
            see_novelties: 'Ver novedades',
            see_comment: 'Ver comentario',
            see_publication: 'Ver publicación',
            see_chat: 'Ver chat',
            see_offer: 'Ver chollo',
            see_coupon: 'Ver cupón',
            see_lottery: 'Ver sorteo',
            see_message: 'Ver mensaje',
            see_coins: 'Ver monedas',
            see_forum: 'Ver foro',
            remove: 'Borrar',
            delete_notification: 'Eliminar esta notificación',
            want_delete_notification: '¿Quieres eliminar esta notificación?',
            delete: 'Eliminar',
            deleted_notification: 'notificación eliminada',
            error_delete_notification: 'Ocurrió un error al eliminar la notificación',
            hide: 'Ocultar',
            cancel: 'Cancelar',
            deactivate: 'Desactivar',
            confirm: 'Confirmar',
            stop_receiving_notifications_like_this: 'Deja de recibir notificaciones como esta',
            want_stop_receiving_notifications_like_this:
                '¿Quieres dejar de recibir notificaciones como esta?',
            silenced_notification: 'Notificación silenciada',
            error_silence_notification: 'Ocurrió un error al silenciar la Notificación',
        },
        cardPostUserInfo: {
            created_at: 'Se creó el',
            reading_time: 'Tiempo de lectura:',
            minutes: 'minutos',
            last_update: 'Última actualización',
            at: 'a las',
        },
        chipGeneric: {
            posts: 'posts',
        },
        dropdownConfirmAction: {
            yes: 'Si',
            no: 'No',
            hide: 'Ocultar',
            expired_success: 'Expirado satisfactoriamente',
        },
        formFilterByLetter: {
            see_more: 'Ver más',
        },
        formFieldAvatarSelect: {
            having_profile_identifies: 'Tener foto de perfil ayuda a ser identificado.',
            photo: 'foto',
        },
        formFieldGiphy: {
            search_gif: 'Buscar gif',
        },
        formFieldPrice: {
            offer_price: 'Precio en oferta',
            regular_price: 'Precio regular',
            ship_price: 'Costo de envío',
            optional: 'Opcional',
            free_promotion: 'Marca si la promoción es gratis',
            free_ship: 'Envío gratis',
            price_offer_higher_than_regular: 'El precio en oferta no debe ser mayor al precio regular',
        },
        formFieldTabs: {
            percent: 'Porcentaje',
            pesos: 'Euros',
            free_ship: 'Envío Gratis',
            discount: 'de descuento',
            required: 'Obligatorio',
            required_discount: 'El tipo de descuento es obligatorio',
        },
        formEmailLoginStrategy: {
            email_sended:
                'Hemos enviado un email de 6 dígitos a tu correo electrónico para recuperar tu contraseña',
            check_email:
                'Revisa tu bandeja de entrada y a continuación, ingresa en las casillas el código enviado',
            hide: 'Ocultar',
            welcome: 'Bienvenido a Bchollos',
        },
        formForgotPassword: {
            noproblem_go_back: 'No hay problema, vuelve atrás',
            goback: 'Regresar',
            have_troubles: '¿Tienes problemas?',
            contact_us: 'contactanos',
            change_password: 'Cambiar contraseña',
            check_email:
                'Introduce tu correo electrónico y te enviaremos un código de 6 dígitos para restablecer tu contraseña',
            set_email: 'Introduce tu correo',
            email: 'Correo electrónico',
            login_without_password: 'Iniciar sesión sin contraseña',
            restore_password: 'Restablecer contraseña',
        },
        formLogin: {
            email_or_username: 'Correo electrónico o username',
            password: 'Contraseña',
            login: 'Iniciar sesión',
            forgot_password: '¿Olvidaste tu contraseña?',
            remember_login: 'Recordar inicio de sesión',
            login_google: 'Iniciar con Google',
            login_facebook: 'Iniciar con Facebook',
            hide: 'Ocultar',
            welcome: 'Bienvenido a Bchollos',
        },
        formMakeComment: {
            answer: 'Responder',
        },
        formQuill: {
            add_comment: 'Añadir un comentario...',
            accept_terms: 'Para poder editar debe aceptar los términos y condiciones del sitio',
            verify_account: 'Para realizar un comentario necesitas verificar tu cuenta',
        },
        formRegister: {
            username: 'Username',
            email: 'Email',
            password: 'Contraseña',
            register: 'Registrarse',
            continue_accept_terms: 'Al continuar, acepto los',
            community_rules: 'Terminos de uso',
            continue_accept_terms2: 'y las',
            privacy_policy: 'Políticas de privacidad',
            continue_accept_terms3: 'de Bchollos.',
            newsletter: 'Recibir el newsletter con ofertas diarias',
            signup_google: 'Regístrate con Google',
            signup_facebook: 'Regístrate con Facebook',
            need_accept_terms: 'Debe estar de acuerdo con los términos y condiciones',
        },
        layoutHeaderLinksWarning: {
            monetized:
                'Algunos enlaces de nuestra web están monetizados. Esto ayuda a que podamos mantener la plataforma.',
        },
        layoutHeaderMenu: {
            menu: 'Menú',
            home: 'Inicio',
            coupons: 'Cupones',
            offers: 'Chollos',
            free: 'Gratis',
            lotteries: 'Sorteos',
            categories: 'Categorías',
            brands: 'Marcas',
            chats: 'Foro',
            novelties: 'Novedades',
            blog: 'Blog',
            top25: 'Top 25',
            sendCoupon: 'Enviar Cupón',
        },
        layoutHeaderSuggestions: {
            stores: 'Tiendas',
            discount: 'descuento',
            discounts: 'descuentos',
            categories: 'Categorías',
            events: 'Eventos',
            no_results: 'No hay resultados',
        },
        layoutHeaderUser: {
            join_free: 'Iniciar/Registro',
            notifications_panel: 'Panel de notificaciones',
            messages_panel: 'Panel de mensajería',
            share: 'Compartir',
            dashboard: 'Dashboard',
            notifications: 'Notificaciones',
            messages: 'Mensajería',
            saved: 'Guardados',
            coins: 'Monedas',
            rewards: 'Premios',
            settings: 'Ajustes',
            close_session: 'Cerrar sesión',
            in_close_session: 'Estás en inicio de sesión',
            session_closed: 'Sesión cerrada de manera forzada',
            share_discounts: 'Compartir chollo/cupón/sorteo/foro',
        },
        layoutInfoPages: {
            index: 'Índice',
        },
        listThreads: {
            index: 'Índice',
            author: 'Autor',
            title: 'Título',
            thread: 'Tema',
            date: 'Fecha',
            all: 'Todo',
            results_not_found: 'No se encontraron resultados',
        },
        microDiscountFooter: {
            related_categories: 'Categorías relacionadas',
            see_more_categories: 'Ver más categorías',
            related_stores: 'Tiendas relacionadas',
            popular_stores: 'Tiendas populares',
            see_more_stores: 'Ver más tiendas',
        },
        microHeatCounterVote: {
            expired: 'EXPIRADO',
        },
        microTabs: {
            order_by: 'Ordenar por',
        },
        modalAuthAcceptTerms: {
            order_by: 'Ordenar por  {{filter}}',
            privacy: 'Privacidad',
            accept_terms: 'Aceptar terminos y condiciones',
            accept_terms_message:
                'Para terminar tu registro, solo debes aceptar las Reglas de la comunidad y la Política de Privacidad de Bchollos.',
            quest_accept_terms_message:
                '¿Estás de acuerdo con las Reglas de la comunidad y la Política de Privacidad de Bchollos?',
            continue_accept_terms: 'Al continuar, acepto las',
            community_rules: 'Reglas de la comunidad',
            continue_accept_terms2: 'y la',
            privacy_policy: 'Políticas de privacidad',
            continue_accept_terms3: 'de Bchollos.',
            continue: 'Continuar',
            hide: 'Ocultar',
            can_interact: 'Ya puedes interactuar en nuestra comunidad',
            login_to_verify: 'Inicia sesión para poder verificar tu cuenta',
            not_valid: 'Puede que tu cuenta no sea válida, contacta el soporte',
            need_accept_terms: 'Necesita aceptar los términos para continuar',
        },
        modalAuthEmailVerification: {
            verify_email: 'Verificar email',
            registered_success:
                'Te has registrado en la comunidad de Bchollos de manera exitosa, te hemos enviado un email de verificación',
            check_spam:
                'Revisa la carpeta de spam para verificar tu cuenta y selecciona que es remitente seguro',
            email_sended: 'Email enviado',
            email_resend: 'Reenviar email',
            email_verify:
                'Te hemos enviado un email de verificación a la dirección de correo electrónico que has registrado',
            email_verify_error:
                'Sucedió un error al enviar el email de verificación, por favor intenta de nuevo en unos minutos',
            continue: 'Continuar',
        },
        modalAuthLogin: {
            login_or_register: 'Inicia sesión o regístrate',
            join_adventages: 'Ventajas de unirte',
            create_account: 'Crear una cuenta',
            login_with_social: 'Inicia sesión con red social',
            login_with_email: 'Inicia con tu correo',
            email_or_username: 'Correo electrónico o username',
            password: 'Contraseña',
            forgot_password: '¿Olvidaste tu contraseña?',
            remember_login: 'Recordar inicio de sesión',
            login: 'Iniciar sesión',
            comment_vote_offers: 'Comenta, vota y envía chollos',
            participate: 'Participa y gana tarjetas regalo de Amazon y más',
            joinus: 'Únete a la mejor comunidad de chollos de España',
            login_google: 'Iniciar con Google',
            login_facebook: 'Iniciar con Facebook',
            hide: 'Ocultar',
            welcome: 'Bienvenido a Bchollos',
            invalid_credentials: 'Credenciales inválidas',
        },
        modalAuthRegister: {
            register: 'Registro',
            welcome: 'Bienvenido a Bchollos, regístrate con Facebook, Google o con tu correo',
            have_account: '¿Ya tienes cuenta?',
            register_email: 'Regístrate con tu correo',
            email: 'Correo electrónico',
            username: 'Nombre de usuario',
            password: 'Contraseña',
            continue_accept_terms: 'Al continuar, acepto las',
            community_rules: 'Reglas de la comunidad',
            continue_accept_terms2: 'y la',
            register_with_social: 'Regístrate con red social',
            privacy_policy: 'Políticas de privacidad',
            continue_accept_terms3: 'de Bchollos.',
            must_accept_terms: 'Debe estar de acuerdo con los términos y condiciones',
            newsletter: 'Recibir el newsletter con los mejores chollos',
            register_account: 'Registrar cuenta',
            register_google: 'Regístrate con Google',
            register_facebook: 'Regístrate con Facebook',
            email_confirmation: 'Confirma tu email. Revisa la carpeta Spam',
        },
        modalAuthResetPasswordInit: {
            reset_password: 'Resetear contraseña',
            ready: 'Todo listo',
            check_email: 'Revisa tu correo y sigue las instrucciones',
        },
        modalCookiesAdjust: {
            back: 'Atrás',
            cookies_adjust: 'Ajustes de cookies',
            save_settings: 'Guardar ajustes',
            read_politics: 'Lee más sobre nuestra Política de Privacidad y Política sobre Cookies',
            cookies_strict: 'Cookies Estrictamente Necesarias',
            cookies_personalization: 'Cookies de Personalización',
            cookies_functional: 'Cookies funcionales',
            cookies_personalization2: 'Cookies Analíticas',
            privacy_policy: 'Política de Privacidad',
            cookies_policy: 'Política sobre Cookies',
            and: 'y',
            hide: 'Ocultar',
            cookies_saved: 'Configuración de cookies realizada satisfactoriamente',
        },
        modalCookiesCookies: {
            cookies_image: 'Imagen de cookies',
            cookies_msg: '¡Ey! ¡Somos las cookies!',
            cookies_description1:
                'Usamos cookies y tecnologías similares para hacer funcionar nuestra web y analizar su tráfico. También las usamos, con tu consentimiento, para mostrarte y medir la actuación de nuestros anuncios y ayudarnos a entender cómo interactúas con el contenido y las funcionalidades de la web. Esto hace que podamos seguir mejorando y personalizando tu experiencia usando nuestra plataforma. Puedes estar tranquilo de que tus datos están seguros con nosotros ya que no los vendemos a terceras partes. Al aceptar este mensaje, estás de acuerdo con nuestra',
            cookies_description2: 'y con nuestra',
            cookies_description3: '(de la que puedes actualizar tus preferencias más adelante).',
            privacy_policy: 'Política de Privacidad',
            cookies_policy: 'Política sobre Cookies',
            accept_all: 'Aceptar todo',
            cookies_adjust: 'Ajustes de cookies',
            continue: 'Continuar sin aceptar',
            hide: 'Ocultar',
            cookies_saved: 'Configuración de cookies realizada satisfactoriamente',
        },
        modalDashboardEmail: {
            change_email: 'Cambia tu correo electrónico',
            write_new_email: 'Introduce un nuevo correo electrónico para tu cuenta',
            email_15_days: 'El correo electrónico solo se puede actualizar cada 15 días',
            write_email: 'Escribe tu correo electrónico',
            cancel: 'Cancelar',
            accept: 'Aceptar',
            profile_updated: 'Su perfil se ha actualizado exitosamente',
        },
        modalDashboardPassword: {
            update_password: 'Actualiza tu contraseña',
            insert_new_password: 'Introduce una nueva contraseña',
            current_password: 'Contraseña actual',
            write_current_password: 'Escribe tu contraseña actual',
            new_password: 'Nueva contraseña',
            write_new_password: 'Escribe tu nueva contraseña',
            repeat_new_password: 'Repite la nueva contraseña',
            cancel: 'Cancelar',
            accept: 'Aceptar',
            profile_updated: 'Su perfil se ha actualizado exitosamente',
            password_updated: 'Su contraseña se ha actualizado exitosamente',
        },
        modalDashboardUsername: {
            change_username: 'Cambia tu username',
            insert_new_username: 'Introduce un nuevo username para tu cuenta',
            username_only_one_per_year: 'El nombre de usuario solo se puede actualizar 1 vez por año',
            write_new_username: 'Escribe tu nombre de usuario',
            cancel: 'Cancelar',
            accept: 'Aceptar',
            no_blank_spaces: 'No puede contener espacios en blanco',
            profile_updated: 'Su perfil se ha actualizado exitosamente',
        },
        modalMessagesNewMessage: {
            new_message: 'Nuevo mensaje',
            to: 'Para',
            username: 'Nombre de usuario',
            no_users_available: 'No hay usuarios disponibles',
            message: 'Escribe tu mensaje',
            cancel: 'Cancelar',
            send: 'Enviar',
        },
        modalCoupon: {
            copy_and_paste1: 'Copia el código y pégalo en',
            copy_and_paste2: 'para obtener tu descuento',
            copied: 'Copiado',
            copy: 'Copiar',
            copy_and_go: 'Copiar cupón e ir a la tienda',
            share: 'Compartir',
            thanks_report: 'Gracias por su reporte',
            coupon_required: 'NO REQUIERE CUPÓN',
        },
        modalGeneric: {
            thanks_one: 'Gracias por tu comentario ¡ha sido enviado exitosamente! Mientras',
            thanks_two: '¡Explora entre las mejores ofertas y cupones!',
        },
        modalImageZoom: {
            image: 'Imagen',
        },
        sidebarCategories: {
            categories: 'Categorías',
            see_more: 'Ver más',
            no_categories: 'No hay categorías disponibles',
        },
        sidebarFeaturedOffers: {
            featured_offers: 'Chollos destacados',
            see_more: 'Ver más',
            get: 'Obtener',
        },
        sidebarFeaturedCoupons: {
            featured_coupons: 'Cupones destacados',
            see_more: 'Ver más',
            get: 'Obtener',
        },
        cardMainSidebarDiscount: {
            see_coupon: 'Ver cupón',
            free: 'GRATIS',
            free_ship: 'ENVÍO GRATIS',
        },
        sidebarGiveMe: {
            get: 'Consígueme también en',
        },
        sidebarMobileCategory: {
            categories: 'Categorías',
        },
        sidebarNetworkPages: {
            socials: 'Redes de',
        },
        sidebarOfferSummary: {
            coupons_for_shop: 'Número de cupones para tienda:',
            offers_for_shop: 'Número de chollos para tienda:',
            last_update: 'Ultima actualización:',
            last_week: 'Nuevos descuentos última semana:',
            all_discounts: 'Número de descuentos totales:',
        },
        sidebarPaymentMethods: {
            methods: 'Métodos de pago',
            accepted_methods: 'Métodos de pago que acepta',
        },
        sidebarShare: {
            share: 'Compartir',
            copy_link: 'Copiar link',
            copied: '¡Copiado!',
            email: 'Correo electrónico',
        },
        sidebarSimilar: {
            see_more: 'Ver todo',
        },
        cardSidebarSomeCategories: {
            similar_categories: 'Categorias similares',
            no_categories: 'No hay categorías similares disponibles',
        },
        sidebarStores: {
            see_more: 'Ver más',
            popular_stores: 'No hay tiendas populares disponibles',
        },
        layoutError: {
            e404: {
                title: 'Esta página no existe',
                message:
                    'No te preocupes, puede ser un error nuestro. Por eso, puedes volver donde estabas o ir a inicio y comenzar de nuevo',
            },
            e410: {
                title: 'Esta página se ha eliminado',
                message:
                    'No te preocupes, puede ser un error nuestro. Por eso, puedes volver donde estabas o ir a inicio y comenzar de nuevo',
            },
            e403: {
                title: 'Forbidden, acceso denegado',
                message:
                    'No te preocupes, puede ser un error nuestro. Por eso, puedes volver donde estabas o ir a inicio y comenzar de nuevo',
            },
            e401: {
                title: 'Página no autorizada',
                message: 'Intenta iniciar sesión',
            },
            e400: {
                title: 'Error de solicitud',
                message: 'Los datos proporcionados no cumplen los requisitos',
            },
            e500: {
                title: 'Error de servidor',
                message: 'Contacta al soporte',
            },

            go_back: 'Volver atrás',
            go_home: 'Ir al inicio',
            try_again:
                'Intenta iniciar sesión con el usuario correcto, actualiza la página y si sigues teniendo problemas',
            send_message: 'envíanos un mensaje.',
            title_seo: 'Error | Bchollos',
            description_seo: 'Pagina de error',
        },
        LinksWarning: {
            title: 'En Bchollos nos esforzamos por verificar cada cupón. Si compras a través de los cupones podemos recibir una comisión.',
            link_text: 'Más información.',
            link_url:
                'https://www.bchollos.es/blog/compras-online/por-que-confiar-en-bchollos-y-como-funciona',
        },
    },
    lib: {
        variant_dict: {
            offer: {
                plural: 'ofertas',
                singular: 'oferta',
                singular_one: 'una oferta',
                plain: 'oferta',
            },
            coupon: {
                plural: 'cupones',
                singular: 'cupón',
                singular_one: 'un cupón',
                plain: 'cupon',
            },
            lottery: {
                plural: 'sorteos',
                singular: 'sorteo',
                singular_one: 'un sorteo',
            },
            chat: {
                plural: 'chats',
                singular: 'chat',
                singular_one: 'un chat',
            },
        },
        date_functions: {
            months: [
                'enero',
                'febrero',
                'marzo',
                'abril',
                'mayo',
                'junio',
                'julio',
                'agosto',
                'septiembre',
                'octubre',
                'noviembre',
                'diciembre',
            ],
            times: {
                sec: 'seg',
                min: 'min',
                hs: 'hs',
                day: 'día/s',
                week: 'sem',
                month: 'mes/es',
                year: 'año/s',
            },
            ends_today: 'Finaliza HOY',
            ends_tomorrow: 'Finaliza MAÑANA',
            ends_in: 'Finaliza en {{days}} días',
            ends_in_week: 'Finaliza en 1 semana',
            ends_at: 'Finaliza el {{date}}',
            datetimeFormat: 'es-ES',
            timezone: 'Europe/Madrid',
        },
    },
    middleware: {
        ifAcceptTerms: {
            text: 'Para compartir un chollo, cupon, sorteo o chat/foro debes aceptar los términos y condiciones del sitio',
        },
        ifAuthenticated: {
            text: 'No tienes permisos para acceder a esta página',
        },
    },
    plugins: {
        axiosInterceptors: {
            for: 'Para',
            need_accept_terms: 'necesitas aceptar los terminos y condiciones',
            need_verify_account: 'necesitas verificar tu cuenta.',
            do_votes: 'realizar votaciones',
            do_save_discounts: 'guardar descuentos',
            do_mark_expired: 'marcar como expirado',
            do_report: 'reportar',
            do_likes: 'dar likes',
            do_comment: 'realizar un comentario',
            do_create_forum: 'crear un foro/chat',
            do_create_lottery: 'crear un sorteo',
            do_create_coupon: 'crear un cupon',
            do_create_offer: 'crear una oferta',
            no_do_action: 'Tu cuenta no permite realizar esta acción',
            no_permission: 'No tienes permisos para acceder a esta página',
        },
    },
    views: {
        categoriesBridge: {
            not_found: 'Página no encontrada',
            news: 'nuevos',
            commented: 'comentados',
        },
        categoriesList: {
            categories: 'Categorías',
            categories_letter: 'Categorías letra',
            categories_letter_starts1: 'Todas las categorías que comienza con la letra',
            categories_letter_starts2: 'se muestran en esta página',
            md: 'Bchollos',
            category_md: 'Categorías | Bchollos',
        },
        categoryDetails: {
            new_offers_in: 'Nuevas chollos en',
            best_offers: 'Mejores chollos aquí',
            description: 'Descripción',
            similar_categories: 'Categorias similares',
            popular_stores: 'Tiendas Populares',
            populars: 'Populares',
            news: 'Nuevos',
            commented: 'Comentados',
            check_offers: 'Mientras, puedes revisar más chollos',
            best_week_discounts: 'Mejores cupones & chollos de la semana',
            offers: 'chollos',
            in: 'en',
            no_offers_found1: 'En este momento no tenemos',
            no_offers_found2: ', pronto volverán a estar disponibles.',
        },
        discountDetails: {
            discounts: 'descuentos',
            coupon_may_not_work: 'El cupón puede no funcionar, se publicó hace más de 3 meses.',
            offer_may_not_work: 'El chollo puede no funcionar, se publicó hace más de 3 meses.',
            expand: 'Expandir',
            published_about: 'Publicado hace',
            updated_at: 'Actualizado hace',
            see_more: 'Ver más',
            offers: 'Ofertas',
            how_participate: 'Como se participa',
            how_much_times_participate: 'Cuantas veces se puede participar',
            member_since: 'Miembro desde:',
            details: 'Detalles',
            information: 'Información',
            ends: 'Termina',
            exclusive: 'Exclusivo',
            verified: 'Verificado',
            start_at: 'Inicia',
            end_at: 'Finaliza',
            local: 'Local',
            sent_from: 'Enviado desde',
            categories: 'Categorías',
            share: 'Compartir',
            affiliate_comission:
                'Cuando haces clic o compras a través de los chollos y códigos podemos recibir una comisión. Esto ayuda a mantener el sitio.',
            related_coupons: 'Cupones relacionados',
            related_offers: 'Chollos relacionados',
            no_exist: 'No hay',
            similar_by_now: ' similares por ahora',
            comments: 'Comentarios',
            following: 'Siguiendo',
            follow: 'Seguir',
            read_more: 'Leer más',
            more_relevant: 'Más relevantes',
            more_recent: 'Más recientes',
            no_comments_yet: 'Todavía no hay comentarios',
            be_first_commenting: 'Comenta el primero y obtén likes en tu comentario',
            no_offer_found: 'No se encontró esta oferta',
            unexpected_error: 'Sucedió un problema inesperado, por favor intente nuevamente mas tarde',
            today: 'HOY',
            tomorrow: 'MAÑANA',
            in_3_days: 'en 3 días',
            see_comments_relevant: 'Ver comentarios más relevantes',
            see_all_comments_more_recent_first:
                'Ver todos los comentarios, los comentarios más recientes se muestran primero',
            save: 'Guardar',
            report: 'Reportar',
            why_report: '¿Por qué lo reportas?',
            spam_ofensive: 'Spam/Ofensivo',
            duplicated: 'Duplicado',
            offer_is_published: 'Ya esta la oferta publicada',
            referred_links: 'Enlaces referidos',
            expired_offer_or_link_not_working: 'Chollo expirado o no funciona el enlace',
            other: 'Otro',
            free_ship: 'Envío gratis',
            free_ship_uppercase: 'ENVÍO GRATIS',
            free: 'GRATIS',
            home: 'Home',
            error_at: 'Error al',
            delete: 'eliminar',
            offer: 'Oferta',
            coupon: 'Cupón',
            coupons: 'cupones',
            save_min: 'guardar',
            something_happens_with: 'ocurre algo con',
            this_offer: 'este chollo',
            this_coupon: 'este cupón',
            saved_a: 'guardada',
            saved: 'guardado',
            deleted: 'eliminado',
            deleted_a: 'eliminada',
            close: 'Cerrar',
            error_on_delete_offer: 'Error al eliminar, ocurre algo con esta oferta',
            error_on_save_coupon: 'Error al guardar, ocurre algo con este cupón',
            okay: 'De acuerdo',
            report_success: 'Su reporte fue enviado exitosamente',
            report_error: 'No se pudo realizar el reporte',
            comment_published: 'Comentario publicado de manera exitosa',
            comment_pending: 'Comentario pendiente de aprobación',
            hide: 'Ocultar',
            comment_cant_create: 'Comentario no se pudo crear',
        },
        discountMask: {
            press_to_copy: 'Presiona el código para copiarlo',
            code_copied_success: '¡Código copiado correctamente!',
            bring: 'Te llevaremos',
            when_copy_code: 'cuando copies el código',
            moving_in: 'Llevándote',
            in: 'en',
            remember_apply_code: 'Recuerda aplicar el código al finalizar la compra',
            expired_coupon: 'Parece que este cupón puede haber expirado',
            error_obtaining_coupon: 'Ocurrió un error al obtener los datos del cupón, contacta el soporte',
            error_obtaining_offer: 'Ocurrió un error al obtener los datos de la oferta, contacta el soporte',
            unexpected_error: 'Sucedió un problema inesperado, por favor intente nuevamente más tarde',
            no_coupon_required: 'NO REQUIERE CUPÓN',
            go_to: 'Ir a',
            go_store: 'Ir a la tienda',
        },
        homepage: {
            best_week_offers: 'Chollos & códigos descuento',
            best_offers_and_coupons_from: 'Mejores chollos y códigos promocionales',
            popular_stores: 'Mejores marcas',
            top_categories: 'Mejores categorias',
            see_more: 'Ver mas',
            popular: 'Populares',
            news: 'Nuevos',
            commented: 'Comentados',
            schemeOrg: {
                email: 'Bchollos@hurtadodeals.com',
                address: 'Madrid, España',
                sameAs: [
                    'https://www.instagram.com/bchollos/',
                    'https://x.com/bcholloses/',
                    'https://www.youtube.com/@Bchollos',
                    'https://www.facebook.com/bcholloses',
                ],
            },
        },
        storeDetails: {
            coupons_of: 'Códigos de descuento :tienda',
            we_dont_have: 'En este momento no tenemos',
            in_the_store: 'en la tienda',
            coupons: 'cupones',
            featured_weekly_offers: 'Mejores descuentos :tienda',
            best_coupons_here: 'Mejores cupones aquí',
            will_be_available: ', pronto volverán a estar disponibles',
            best_coupons: 'Mientras, puedes revisar los mejores cupones',
            offers: 'Ofertas',
            check_offers: 'Mientras, puedes revisar los mejores chollos',
            best_offers: 'Mejores chollos aquí',
            recent_coupons: 'Cupones recientes de',
            discount: 'Descuento',
            details: 'Detalles',
            discount_details: 'del descuento',
            expires: 'Expira',
            free_ship: 'ENVÍO GRATIS',
            free: 'GRATIS',
            offers_and_coupons_expired_from: 'Chollos y Códigos descuento expirados de',
            promotions_from: 'Promociones de',
            discount_resume: 'Resumen de descuentos',
            similar_stores: 'Tiendas Similares',
            popular_stores: 'Tiendas Populares',
            home: 'Home',
            stores: 'Tiendas',
            valid_until_advice: 'Válido hasta nuevo aviso',
            comissions_on: {
                title: 'Esta tienda nos ayuda a mantener Bchollos',
                header_link_text:
                    'Bchollos puede ganar una comisión cuando haces utilices los cupones de esta página.',
                texts: [
                    'Al usar un cupón en esta página, Bchollos podría recibir una comisión mediante un acuerdo de afiliación con _STORE_. Esto no representa ningún coste adicional para ti y nos permite seguir adelante con nuestra misión en Bchollos: facilitarte el ahorro en tus compras online mediante nuestros cupones.',
                    'En Bchollos, la mayoría de las tiendas y marcas presentes no están vinculadas a acuerdos de afiliación, por lo que no recibimos comisiones ni ingresos directos de estas. Nuestro compromiso es que sea nuestra página web donde encuentres todas las tiendas, categorías y eventos para ahorrar en tus compras por internet.',
                    'La confianza de los compradores es muy importante para nosotros, y deseamos mantener una total transparencia sobre la forma en que obtenemos ingresos. En las tiendas que generamos ingresos, están marcadas con este mensaje en la misma.',
                    'Por otro lado, evitamos mostrar anuncios o banners en nuestro sitio (porque tampoco nos gusta, como a ti), y no comercializamos ningún tipo de información personal o de compras de nuestros usuarios.',
                ],
                link: {
                    path: '/blog/general/te-contamos-como-bchollos-genera-ingresos-y-como-lo-mantenemos',
                    text: 'Descubre más sobre cómo Bchollos genera ingresos',
                },
            },
            comissions_free: {
                title: 'No generamos comisiones ni anuncios con esta tienda en Bchollos',
                header_link_text:
                    'No ganamos ninguna comisión en esta tienda ni mostramos anuncios. Lo hacemos por ti.',
                texts: [
                    'En Bchollos, no generamos ingresos directamente de esta página. Aquí no encontrarás enlaces de afiliación, no obtenemos comisiones por las compras en _STORE_, evitamos los anuncios publicitarios (sabemos que son muy molestos) y no comercializamos tu información personal o de compras.',
                    'Nuestro objetivo es facilitar el ahorro a los consumidores en sus compras por internet. Esto implica proporcionar códigos promocionales de la mayor cantidad de tiendas posible, aun sin recibir beneficios económicos por ello.',
                    'Aunque el acceso a esta página es gratis, el funcionamiento de Bchollos tiene un costo. En ciertas páginas, podemos recibir una comisión mediante acuerdos de afiliación si utilizas un código promocional o haces clic en él. Valoramos enormemente la confianza de nuestros usuarios y aspiramos a una total transparencia en nuestros métodos de monetización. Las páginas que contienen enlaces de afiliación están claramente marcadas.',
                ],
                link: {
                    path: '/blog/general/te-contamos-como-bchollos-genera-ingresos-y-como-lo-mantenemos',
                    text: ' Descubre más sobre cómo Bchollos genera ingresos',
                },
            },
            how_we_verify_coupons: {
                title: '¿Como verificamos los cupones?',
                texts: [
                    'En Bchollos.es somos 100% transparentes con las ofertas y códigos promocionales que subimos, ya que pasan un proceso de verificación y filtrado. Gracias a los usuarios que comparten sus ofertas y cupones y a un equipo editorial que sube cada día chollos y códigos de descuento de _STORE_, puedes ahorrar una pequeña cantidad de dinero en cada compra que realices. La última vez que se actualizaron los cupones y chollos de _STORE_ fue el día _UPDATED_, por lo que todas nuestros códigos de descuento están verificados y comprobados a ese día.',
                ],
                link: {
                    path: '/blog/compras-online/por-que-confiar-en-bchollos-y-como-funciona',
                    text: 'Leer más sobre cómo verificamos los cupones.',
                },
            },
        },
        storesBridge: {
            category_not_found: 'Esta categoría no fue encontrada',
            store_not_found: 'Esta tienda no fue encontrada',
            error: 'Sucedió un problema inesperado, por favor intente nuevamente más tarde',
        },
        storesList: {
            popular_stores: 'Tiendas Populares',
            stores_letter: 'Tiendas letra',
            md: '| Bchollos',
            stores: 'Marcas',
            stores_with_letter1: 'Todas las marcas que comienzan con la letra',
            stores_with_letter2: 'se muestran en esta página.',
        },
    },
    pages: {
        landing: {
            commission_on: 'puede ganar una comisión cuando ces los cupones de esta página.',
            commission_free:
                'No ganamos ninguna comisión en esta tienda ni mostramos anuncios. Es 100% libre.',
        },
        subscribe: {
            success: 'Satisfactorio',
            ops: 'Oops...',
            congrats: 'Felicidades! Ya estas suscrito a nuestro newsletter!',
            existing: 'Al parecer ya estabas suscrito a nuestro newsletter!',
            error: 'Al parecer ocurrió un error al intentar suscribirte, pero puedes intentarlo nuevamente en unos segundos.',
            title: 'Suscripción | Bchollos',
            description: 'Suscripción al newsletter de Bchollos',
        },
        unsubscribe: {
            success: 'Satisfactorio',
            ops: 'Oops...',
            congrats: 'Te acabas de desuscribir de nuestro newsletter',
            error: 'Al parecer ocurrió un error al intentar desuscribirte, pero puedes intentarlo nuevamente en unos segundos.',
            title: 'Desuscripción | Bchollos',
            description: 'Desuscripción al newsletter de Bchollos',
        },
        blogArticle: {
            index: 'Índice',
            valorate: 'Valorar',
            similar_articles: 'Artículos similares',
            event_not_found: 'Este evento no fue encontrado',
            error: 'Sucedió un problema inesperado, por favor intente nuevamente mas tarde',
            hide: 'Ocultar',
            error_on_valorate: 'Ocurrió un error al valorar el artículo',
            home: 'Home',
            blog: 'Blog',
        },
        blogCategory: {
            all_articles_about: 'Todos los artículos sobre',
            category_no_contents: 'Esta categoría no tiene contenidos aún',
            category_not_found: 'Esta categoría no fue encontrada',
            unexpected_error: 'Sucedió un problema inesperado, por favor intente nuevamente más tarde',
            articles_about: 'Artículos sobre {{category}} | Bchollos',
            all_articles: 'Aquí encontrarás todos los artículos sobre {{category}} en Bchollos',
            home: 'Home',
            blog: 'Blog',
        },
        blog: {
            advices: 'Consejos',
            and: 'y',
            guides: 'guías',
            to_save_money: 'para ahorrar dinero',
            best_guides: 'Guías, artículos y tips para ahorrar en tus compras online.',
            articles_about: 'Artículos sobre',
            see_more: 'Ver más',
            simpleh1: '',
            simpleh2: '',
        },
        compartirCuponCrear: {
            at_coupon: 'del cupón',
            title_seo: 'Compartir un cupón | Bchollos',
            description_seo: 'Página para compartir tus cupones',
            error_on_create: 'Error al crear el cupón, por favor, intentalo nuevamente',
            coupon_created: 'Cupón creado satisfactoriamente',
        },
        compartirCupon: {
            title_seo: 'Compartir un cupón | Bchollos',
            description_seo: 'Página para compartir tus cupones',
        },
        compartirOfertaCrear: {
            of_the_offer: 'de la oferta',
            title_seo: 'Compartir un chollo | Bchollos',
            description_seo: 'Página para compartir tus chollos',
            error_on_create: 'Error al crear la oferta, por favor, intentalo nuevamente',
            offer_success: 'Chollo creada satisfactoriamente',
        },
        compartirOferta: {
            title_seo: 'Compartir un chollo | Bchollos',
            description_seo: 'Página para compartir tus chollos',
        },
        compartirChat: {
            chat: 'chat',
            title_seo: 'Compartir hilo | Bchollos',
            description_seo: 'Página para compartir un hilo en el foro',
            error_on_create: 'Error al crear el chat, por favor, intentalo nuevamente',
        },
        compartirFinalizado: {
            post_received: 'Hemos recibido tu',
            in_revision: 'y está en revisión.',
            when_published: 'Cuando esté publicada recibirás una notificación.',
            be_patient: 'Ten paciencia, lo revisaremos en breve',
            recommend: 'Mientras que se publica tú {{post}}, te recomendamos:',
            see_offers: 'Ver Chollos Populares',
            see_coupons: 'Ver Cupones Populares',
            your: 'Tu',
            published: ' se ha publicado con éxito!',
            see_post: 'Aquí puedes ver tu',
            title_seo: 'Finalizado! | Bchollos',
            description_seo: 'La publicación se realizó satisfactoriamente',
        },
        compartir: {
            share_question: '¿Qué te gustaría compartir?',
            offer: 'Oferta',
            share_offer: 'Comparte una oferta o promoción que sea atractiva',
            coupon: 'Cupón',
            share_coupon: 'Comparte un cupón y no te olvides de incluir el código de descuento',
            lottery: 'Sorteo',
            share_if_socials: 'Si has visto un sorteo en redes sociales o en algún lugar, compártelo',
            chat: 'Chat y discusiones',
            open_thread: 'Abre un debate o pregunta a la comunidad cualquier cosa',
            title_seo: 'Compartir | Bchollos',
            description_seo:
                'Página para compartir tus publicaciones como ofertas, cupones, sorteos y chats...',
        },
        compartirSorteo: {
            of_lottery: 'del sorteo',
            title_seo: 'Compartir sorteo | Bchollos',
            description_seo: 'Página para compartir un sorteo.',
            error: 'Error al crear el sorteo, por favor, intentalo nuevamente',
        },
        cupones: {
            featured_coupons: 'Códigos descuento destacados',
            featured_stores: 'Marcas destacadas',
            description: 'Description',
            featured_shop: 'Marcas Populares',
            populars: 'Populares',
            news: 'Nuevos',
            commented: 'Comentados',
            home: 'Home',
            coupons: 'Cupones',
        },
        dashboardUserActividad: {
            recent_activity: 'Actividad reciente',
            no_activity: 'Sin actividad en los últimos 30 días',
            title_seo: 'Dashboard de {{name}} | Bchollos',
            description_seo: 'Actividades recientes que {{name}} ha realizado en tu cuenta',
        },
        dashboardUserChats: {
            chats: 'Chats',
            error: '¡outs! al parecer este usuario aún no ha creado o participado en el foro',
            title_seo: 'Dashboard de {{name}} | Chats | Bchollos',
            description_seo: 'Chats/foros que {{name}} ha publicado en Bchollos',
        },
        dashboardUserDescuentos: {
            offer_coupons_published: 'Chollos/cupones publicados',
            no_discounts: 'Este usuario aún no tiene chollos/cupones publicadas',
            title_seo: 'Dashboard de {{name}} | Descuentos | Bchollos',
            description_seo: 'Descuentos que {{name}} ha publicado en Bchollos',
        },
        dashboardUserSorteos: {
            lotteries: 'Sorteos',
            error: '¡Outs! al parecer este usuario no tiene sorteos',
            title_seo: 'Dashboard de {{name}} | Sorteos | Bchollos',
            description_seo: 'Sorteos qué {{name}} ha publicado en Bchollos',
        },
        dashboardUserIndex: {
            see: 'Ver',
            recent_activity: 'Actividad reciente',
            discounts_published: 'Ofertas/cupones publicados',
            chats: 'Chats',
            lotteries: 'Sorteos',
            title_seo: 'Dashboard de {{name}} | Bchollos',
            description_seo: 'Dashboard de {{name}}',
        },
        dashboardUserMain: {
            member_since: 'Miembro desde:',
            followers: 'Seguidores',
            rank: 'Ranking de usuario',
            follow: 'Seguir',
            rewards: 'Recompensas',
            stats: 'Estadísticas',
            verified_user: 'Usuario verificado',
            user_was_verified: 'Tu usuario ya ha sido verificado',
            verified_description:
                'Es un usuario que tiene la insignia de “Verificado” y lo consigue tras 25 ofertas/cupones aprobados y 50 comentarios aprobados. Se le debe enviar un correo electrónico para informarle y además se le informará por la mensajería y en notificaciones.',
            offer_coupon: 'Cupones/ofertas',
            comments: 'Comentarios',
            profile_not_found: 'Perfil de usuario no encontrado',
            coins_available: 'Monedas disponibles',
            coins_total: 'Monedas totales',
            awards: 'Premios',
            lotteries: 'Sorteos',
            chats: 'Chats',
            likes_sended: 'Likes enviados en ofertas y comentarios',
        },
        dashboardActividad: {
            recent_activity: 'Actividad reciente',
            no_activity: 'Sin actividad en los últimos 30 días',
            publish_offer: 'Publicar oferta',
            publish_coupon: 'Publicar cupón',
            title_seo: 'Tu dashboard | Bchollos',
            description_seo: 'Actividades recientes que has realizado en tu cuenta',
        },
        dashboardAjustes: {
            telegram_url: 'https://t.me/bchollos',
            account: 'Mi cuenta',
            username: 'Username',
            change: 'Cambiar',
            password: 'Contraseña',
            set_new_password: 'Establece una nueva contraseña',
            new_password: 'Nueva contraseña',
            email: 'Correo electrónico',
            not_verified: 'No verificado (Revisa tu buzón de emails)',
            resend_email: 'Reenviar email de verificación',
            delete_account: 'Eliminar cuenta',
            notifications: 'Notificaciones',
            notifications_web: 'Notificaciones web',
            notifications_email: 'Notificaciones por email',
            new_novelties_post: 'Nuevo post de novedades ha sido publicado.',
            new_private_message: 'Mensaje nuevo en tu bandeja de entrada.',
            coins_received: 'Recibí monedas.',
            comments: 'Comentarios',
            someone_answers_to_comment: 'Alguien responda a un comentario mío.',
            multiple_likes_to_comment: 'Múltiples usuarios dan like a un comentario mío.',
            my_comment_liked: 'Alguien da me gusta a un comentario mío.',
            offers_coupons: 'Chollos/cupones',
            discount_first_grade: 'Mi chollo/cupón alcanza el primer grado de temperatura.',
            discount_popular_250: 'Mi chollo/cupón sea popular (+250º)',
            discount_popular_500: 'Mi chollo/cupón sea popular (+500º)',
            discount_other_popular_500: 'Un chollo/cupón de otra persona sea muy popular (+500º)',
            discount_not_popular_minus_25: 'Mi chollo/cupón no sea popular (-25°)',
            discount_expired: 'Un chollo/cupón mía haya expirado.',
            report_publication_approved: 'Mi reporte de una publicación es aprobado.',
            report_publication_rejected: 'Mi reporte de una publicación es rechazado.',
            specials: 'Especiales',
            follow_telegram: 'Seguir canal de Telegram',
            go_telegram: 'Ir al canal de Telegram',
            telegram_description:
                'En este canal de Telegram envíamos las mejores ofertas, cupones y sorteos de cada día, un resumen de la mejor selección para que no tengas que entrar a la web a cada rato.',
            email_offers: 'Ofertas por email',
            receive_best_offers_daily: 'Recibir notificaciones de mejores ofertas diarias.',
            receive_best_offers_week: 'Recibir notificaciones de las mejores ofertas de la semana.',
            receive_recomendations: 'Recibir notificaciones de recomendaciones de Bchollos.',
            newsletter: 'Newsletter',
            receive_newsletter: 'Recibir notificaciones de newsletter.',
            receive_no_readed_notifications: 'Recibir notificaciones no leídas de forma semanal.',
            general: 'General',
            novelties_published: 'Un nuevo post de novedades ha sido publicado.',
            inbox_received: 'Un mensaje nuevo en tu bandeja de entrada.',
            when_someone_tags_in_comment: 'Cuando alguien me etiqueta en un comentario.',
            when_someone_answer_comment: 'Cuando alguien responda a un comentario mío.',
            when_post_receives_first_comment: 'Cuando una publicación mía recibe el primer comentario.',
            when_post_receives_commments: 'Cuando una publicación mía recibe comentarios.',
            my_comment_approved: 'Un comentario tuyo ha sido aprobado.',
            when_discount_popular_250: 'Cuando mi oferta/cupón sea popular (+250º).',
            when_discount_popular_500: 'Cuando mi oferta/cupón sea muy popular (+500º).',
            when_discount_minus_25: 'Cuando mi oferta/cupón no sea popular (-25°).',
            when_other_discount_popular_500:
                'Cuando una oferta/cupón de otra persona sea muy popular (+500º).',
            when_discount_expired: 'Cuando una oferta/cupón mía haya expirado.',
            when_discount_published: 'Cuando mi oferta/cupón se haya publicado.',
            save_settings: 'Guardar ajustes',
            social_connection: 'Conexión social',
            created_accounts: 'Cuentas conectadas',
            google_connected: 'Conectado con Google',
            facebook_connected: 'Conectado con Facebook',
            preferences_get_error: 'No se pudieron obtener las preferencias del usuario',
            title_seo: 'Ajustes | Bchollos',
            description_seo: 'Configuración de tu cuenta',
            google_connected_account: 'Cuenta Google conectada:',
            google_not_connected: 'No tienes tu cuenta de Google conectada.',
            facebook_connected_account: 'Cuenta Facebook conectada:',
            facebook_not_connected: 'No tienes tu cuenta de Facebook conectada.',
            success_account_image: 'Su imagen de perfil se actualizó de manera exitosa',
            error_account_image: 'Error al actualizar su imagen de perfil',
            success_updating_preferences_web:
                'Se actualizaron las preferencias de notificaciones web de manera exitosa',
            error_updating_preferences_web: 'Error al actualizar las preferencias de notificaciones web',
            success_updating_preferences_email:
                'Se actualizaron las preferencias de correo de manera exitosa',
            error_updating_preferences_email: 'Error al actualizar las preferencias de correo',
            email_verification_sended: 'Te hemos enviado un email de verificación',
            are_sure_delete_account: '¿Estás seguro de que quieres eliminar tu cuenta?',
            success_deleting_account: 'Cuenta eliminada de manera exitosa',
            error_deleting_account: 'Error al intentar eliminar cuenta',
            cancel: 'Cancelar',
        },
        dashboardDescuentos: {
            discounts_published: 'Chollos/cupones publicados',
            no_discounts_published: 'Aun no tienes chollos/cupones publicados',
            publish_one: '¡Comienza a publicar una ya!',
            title_seo: 'Descuentos | Bchollos',
            description_seo: 'Descuentos que has publicado en Bchollos',
        },
        dashboardGuardados: {
            discounts_saved: 'Chollos/cupones guardados',
            no_discounts_saved: 'Aún no tienes chollos/cupones guardados',
            title_seo: 'Guardados | Bchollos',
            description_seo: 'Descuentos que has guardado en tu cuenta',
        },
        dashboardIndex: {
            see: 'Ver',
            recent_activity: 'Actividad reciente',
            discounts_published: 'Ofertas/cupones publicados',
            discounts_saved: 'Ofertas/cupones guardados',
            chats: 'Mis chats',
            publicChats: 'Chats',
            settings: 'Ajustes',
            title_seo: 'Tu dashboard | MEGAdescuentos',
            description_seo: 'Tu dashboard de usuario',
        },

        dashboardIndexMain: {
            member_since: 'Miembro desde:',
            followers: 'Seguidores ',
            ranking: 'Ranking de usuario',
            settings: 'Ajustes de cuenta',
            rewards: 'Recompensas',
            stats: 'Estadísticas',
            verified_user: 'Usuario verificado',
            user_was_verified: 'Tu usuario ya ha sido verificado',
            verified_description:
                'Es un usuario que tiene la insignia de “Verificado” y lo consigue tras 25 ofertas/cupones aprobados y 50 comentarios aprobados. Se le debe enviar un correo electrónico para informarle y además se le informará por la mensajería y en notificaciones.',
            discounts: 'Cupones/ofertas',
            comments: 'Comentarios',
            account: 'Mi cuenta',
            notifications: 'Notificaciones',
            social_connection: 'Conexión social',
            close_session: 'Cerrar sesión',
            profile_not_found: 'Perfil de usuario no encontrado',
            coins_available: 'Monedas disponibles',
            coins_total: 'Monedas totales',
            awards: 'Premios',
            chats: 'Chats',
            likes_sended: 'Likes enviados en ofertas y comentarios',
            title_seo: 'Dashboard | MEGAdescuentos',
            description_seo: 'Dashboard de usuario',
            session_closed_forced: 'Sesión cerrada de manera forzada',
        },
        dashboardMonedas: {
            coins: 'Monedas',
            action: 'Acción',
            user_not_verified: 'Usuario no verificado',
            verified_user: 'Usuario verificado',
            information: 'Información',
            points: 'puntos',
            coins_quantity: 'Monedas',
            historical_events: 'Histórico de eventos',
            date: 'Fecha',
            received_coins: 'Monedas conseguidas',
            no_coins_earned: 'Todavía no has ganado monedas',
            faq: 'Preguntas frecuentes',
            register_login: 'Registro y login',
            action_1_time: 'Acción: 1 vez.',
            comments: 'Comentarios',
            action_5_time_per_day: 'Acción: 5 veces por día.',
            report_expired_offer: 'Reportar oferta expirada',
            action_5_time_per_day_approved: 'Acción: 5 veces por día (aprobado).',
            offer_published: 'Oferta publicada',
            action_1_time_per_day_if_approved: 'Acción: 1 vez por día (si es aprobado).',
            coupon_published: 'Cupón publicado',
            lottery_published: 'Sorteo publicado',
            action_no_limits: 'Acción: sin límite.',
            discount_reach_250: 'Chollo/cupón alcanza 250°',
            discount_reach_500: 'Chollo/cupón alcanza 500°',
            check_in_daily: 'Check-in diario',
            action_1_time_per_day: 'Acción: 1 vez por día',
            antiquity_1_month: 'Usuario alcanza antigüedad de 1 mes',
            antiquity_6_month: 'Usuario alcanza antigüedad de 6 meses',
            anniversary: 'Usuario aniversario',
            action_1_time_annually: 'Acción: 1 vez anual.',
            coins_are_free: '¿Las monedas son gratis de conseguir?',
            coins_are_free_answer:
                'Sí, conseguir monedas es totalmente gratis y se consigue participando en la comunidad, accediendo, comentando y publicando ofertas, cupones o discusiones, entre otras muchas formas de conseguirlo.',
            can_buy_coins: '¿Se pueden comprar monedas extra?',
            can_buy_coins_answer:
                'No, al menos de momento. Pero si consigues que tu usuario sea verificado (nivel 1) conseguirás casi siempre el doble de monedas por cada acción que realices. Para ser usuario verificado solo necesitas tener 25 descuentos publicados y 50 comentarios publicados.',
            how_can_use_coins: '¿Cómo puedo gastar mis monedas?',
            how_can_use_coins_answer:
                'Cuando tengas monedas suficientes dirígete a la sección Premios y automáticamente verás los que hay disponibles para solicitarlo. Una vez solicitamos verificaremos y aprobaremos tu premio si cumples con los requisitos.',
            title_seo: 'Monedas | Bchollos',
            description_seo:
                'Conoce las monedas que te podemos ofrecer para conseguir descuentos en Bchollos',
        },
        dashboardPremios: {
            awards: 'Premios',
            available_awards: 'Premios disponibles',
            historical_awards: 'Histórico de premios ganados',
            points: 'puntos',
            redeem: 'Canjear premio',
            no_prizes_available: 'En este momento no hay premios disponibles',
            come_back_later:
                'Vuelve más tarde para intentar canjear tus monedas por premios. En breve estarán disponibles.',
            coins: 'monedas',
            award_redeemed: 'Premio canjeado',
            no_prizes_achieved: 'Ningún premio conseguido aún',
            keep_farming:
                'Sigue consiguiendo monedas y aquí verás tus premios conseguidos muy pronto. Participa, comparte y comenta para ser de los mejores usuarios',
            title_seo: 'Premios | Bchollos',
            description_seo: 'Premios disponibles en Bchollos',
            redeem_success_awaiting: 'Premio canjeado, pendiente de aprobación',
            close: 'Cerrar',
            error_on_redeem: 'Error al canjear premio',
        },
        dashboardSorteos: {
            my_lotteries: 'Mis sorteos',
            ups: '¡Outs! al parecer aun no tienes sorteos',
            start: '¡Comienza ya!',
            title_seo: 'Sorteos | Bchollos',
            description_seo: 'Sorteos que has publicado en Bchollos',
        },
        eventosSlug: {
            featured_stores: 'Tiendas destacadas',
            featured_discounts: 'Descuentos destacados',
            no_discounts: 'No hay descuentos todavía activos, vuelve más tarde',
            enjoy: 'Disfruta de TODOS los cupones y descuentos en',
            in_stores: 'en estas tiendas',
            event_not_found: 'Este evento no fue encontrado',
            unexpected_error: 'Sucedió un problema inesperado, por favor intente nuevamente mas tarde',
            home: 'Home',
            events: 'Eventos',
        },
        eventosIndex: {
            active_events: 'Eventos Activos',
            no_active_events: 'Al parecer no hay ningún evento activo',
            next_events: 'Próximos eventos',
            not_events_found: 'No encontramos eventos disponibles para el próximo año',
            missed_events: 'Eventos que te has perdido',
            no_expired_events: 'Al parecer no hay eventos expirados',
            no_discounts_active: 'No hay descuentos todavía activos, vuelve más tarde',
            save: 'Ahorra con los cupones exclusivos de Bchollos',
        },
        foros: {
            threads: 'Temas',
            create_new_forum: 'Crear nuevo tema',
            forum: 'Foros',
            popular_shops: 'Tiendas Populares',
            home: 'Home',
            categories: 'Categorias',
        },
        forosSlug: {
            ago: 'Hace',
            comments: 'Comentarios',
            following: 'Siguiendo',
            follow: 'Seguir',
            more_relevant: 'Más relevantes',
            more_resents: 'Más recientes',
            no_comments_yet: 'Todavía no hay comentarios',
            comment_first: 'Comenta el primero y obtén me gustas en tu comentario',
            discussions: 'Discusiones',
            see_more: 'Ver más',
            thread_not_found: 'Este hilo no fue encontrado',
            unexpected_error: 'Sucedió un problema inesperado, por favor intente nuevamente mas tarde',
            years: 'años',
            months: 'meses',
            weeks: 'semanas',
            days: 'días',
            hours: 'horas',
            minutes: 'minutos',
            seconds: 'segundos',
            see_more_relevant_comments: 'Ver comentarios más relevantes',
            see_all_comments: 'Ver todos los comentarios, los comentarios más recientes se muestran primero',
            report: 'Reportar',
            why_report: '¿Por qué lo reportas?',
            spam_offensive: 'Spam/Ofensivo',
            duplicated: 'Duplicado',
            offer_published: 'Ya esta la oferta publicada',
            referred_links: 'Enlaces referidos',
            other: 'Otro',
            home: 'Home',
            forums: 'Foros',
            new: 'Nuevo',
            popular: 'Popular',
            hide: 'Ocultar',
            published_comments: 'Comentario publicado de manera exitosa',
            pending_comment: 'Comentario pendiente de aprobación',
            comment_error: 'Comentario no se pudo crear',
            okay: 'De acuerdo',
            report_success: 'Su reporte fue enviado exitosamente',
            close: 'Cerrar',
            report_error: 'No se pudo realizar el reporte',
        },
        mensajeria: {
            inbox: 'Inbox',
            send_message: 'Enviar mensaje',
            no_readed_conversations: 'Aún no hay conversaciones iniciadas',
            load_more_messages: 'Cargar más mensajes',
            write_your_message: 'Escribe tu mensaje aquí',
            send: 'Enviar',
            to_start: 'Para empezar a conversar, selecciona un chat o inicia uno',
            title_seo: 'Mensajería | Bchollos',
            description_seo: 'Página para ver y enviar mensajes entre usuarios',
            delete: 'Eliminar',
            delete_conversation: 'Eliminar esta conversación',
            want_delete_conversation: 'Quieres eliminar esta conversación',
            conversation_deleted: 'Conversacion eliminada',
            hide: 'Ocultar',
            error: 'Ocurrió un error al eliminar la conversación',
            cancel: 'Cancelar',
            mute_conversation: 'notificaciones en esta conversación',
            unmute_conversation: 'Desilenciar notificaciones en esta conversación',
            want: 'Quieres',
            this_conversation: 'esta conversación?',
            conversation_muted: 'Conversación silenciada',
            error_on_conversation_mute: 'Ocurrió un error al silenciar la conversación',
            unmute_may: 'Desilenciar',
            mute_may: 'Silenciar',
            unmute: 'desilenciar',
            mute: 'silenciar',
            unexpected_error: 'Ocurrió un error inesperado, intente nuevamente en unos minutos',
            error_on_send_message: 'Ocurrió un error al enviar el mensaje',
        },
        novedadesSlug: {
            last_novelties: 'Últimas novedades',
            novelty_not_found: 'Esta novedad no fue encontrada',
            unexpected_error: 'Sucedió un problema inesperado, por favor intente nuevamente mas tarde',
            home: 'Home',
            novelties: 'Novedades',
        },
        novedades: {
            novelties: 'Novedades',
            no_novelties: 'Aún no hay ninguna novedad disponible',
        },
        ofertas: {
            featured_offers: 'Ofertas destacados',
            popular_brands: 'Marcas destacadas',
            description: 'Description',
            popular_stores: 'Tiendas Populares',
            populars: 'Populares',
            news: 'Nuevos',
            commented: 'Comentados',
            home: 'Home',
            offers: 'Ofertas',
        },
        sorteos: {
            participate: 'Participa gratis en sorteos para ganar',
            awards_and_gifts: 'premios y regalos',
            competitions: 'Competiciones de hoy',
            order_by: 'Ordenar por',
            news: 'Nuevos',
            populars: 'Populares',
            popular_stores: 'Tiendas Populares',
        },
        contacto: {
            contact: 'Contáctanos',
            title: 'Título',
            details: 'Introduce el asunto de tu consulta',
            affair: 'Asunto',
            name: 'Nombre',
            required: 'Este campo es requerido',
            email_address: 'Correo eléctronico',
            message: 'Mensaje',
            remember: 'Recuerda que te estás poniendo en contacto con Bchollos y no con alguna tienda',
            send_message: 'Enviar mensaje',
            why_contact: '¿Sobre qué asunto nos contactas?',
            share_offer: 'Compartir oferta',
            account: 'Mi Cuenta',
            commercial: 'Comercial',
            suggests: 'Sugerencias',
            complaints_legal: 'Quejas y Legal',
            others: 'Otros',
            message_error: 'Sucedió un error, por favor, intentelo nuevamente mas tarde',
            message_success: 'Tu mensaje ha sido enviado correctamente',
        },
        contrasenaActualizadaExitosamente: {
            success: 'Contraseña actualizada correctamente',
            enjoy: 'Ahora disfruta de las ofertas/cupones en Bchollos',
            see_coupons: 'Ver cupones',
            see_offers: 'Ver ofertas',
            redirect: 'Serás redirigido al login en segundos',
            title_seo: 'Contraseña actualizada exitosamente | Bchollos',
            description_seo: 'Página de contraseña actualizada exitosamente',
        },
        expirados: {
            lost: 'Mira lo que te has perdido',
            expired_recently: 'Ofertas y cupones que expiraron recientemente',
            no_discounts_expired: 'No hay ofertas/cupones expirados',
            found_discounts: '¡No todo es gris! Encuentra ofertas y cupones en otras búsquedas',
            featured_stores: 'Tiendas Populares',
            coupons: 'Códigos descuento',
            offers: 'Chollos',
            lotteries: 'Sorteos',
            categories: 'Categorías',
            brands: 'Marcas',
            register: 'Registrarse',
            popular: 'Populares',
            news: 'Nuevos',
            commented: 'Comentados',
            all_coupons:
                'Todos los cupones & ofertas expirados recientemente en :month :year. Por si te has perdido algo.',
        },
        extensionDesinstalada: {
            sorry: 'Sentimos verte partir',
            mistake: '¿Desinstalaste por error?',
            reinstall: 'Reinstalar extensión',
            why: 'Si lo hizo a propósito, ayúdenos a mejorar indicándonos por qué desinstalo Bchollos',
            select: 'Selecciona las casillas que correspondan',
            think: 'Dinos que piensas...',
            other_comments: '¿Qué otros comentarios tienes para nosotros?',
            send: 'Enviar',
            by_mistake: 'Lo instalé por error.',
            codes_not_work: 'Los códigos no funcionaron.',
            md_not_work: 'Bchollos no funciona donde compro.',
            md_confuse: 'Encontré a Bchollos algo confuso.',
            savings_useless: 'Los ahorros no fueron útiles.',
            other: 'Otro.',
            title_seo: 'Extensión desinstalada | Bchollos',
            description_seo: 'Página de extensión desinstalada',
            error: 'Error al enviar datos',
            pick_one: 'Debes seleccionar al menos una opción o darnos un comentario.',
            close: 'Cerrar',
        },
        extension: {
            amazing_discounts: 'Descuentos increíbles y 100% gratis con la extensión de Bchollos',
            find_codes:
                '¿Te gusta comprar en línea? Encontrar códigos y ofertas para sus marcas favoritas ahora es más fácil y gratis.',
            install: 'Instalar ahora gratis',
            free: 'Es Gratis',
            free_no_cost: '100% gratuita y sin costo, además de actualizaciones frecuentes.',
            apply: 'Aplica automáticamente',
            we_tell_auto: 'Te avisaremos de forma automática cuando encontremos cupones & ofertas.',
            register_not_required: 'No requiere registro',
            install_and_go: 'Solo instalar y ya. No tienes que registrarte ni perder tiempo.',
            best_extension: 'La mejor extensión de ofertas y cupones',
            developing:
                'Estamos desarrollando la mejor extensión de navegador de cupones & ofertas de España, para que puedas ahorrar en tus marcas favoritas y no tengas que perder tiempo buscando descuentos que luego no funcionan. Instala hoy y comienza a ahorrar.',
            find_best_savings: 'Encuentra los mejores ahorros',
            brands: 'Rappi, Linio, AliExpress, Walmart y mucho más',
            best_brands:
                'Las mejores marcas de España se encuentran en Bchollos, con los mejores cupones y ofertas. Encuentra promociones para marcas como Xcaret, Rappi, AliExpress o adidas España, entre otros muchos. Más de 250 marcas ya están disponibles ¿Te lo vas a perder?',
            get_now: '¡Consíguelo ahora!',
            download_extension: 'Descargar Extensión',
            extension_free:
                'La extensión es completamente gratuita. No hay suscripciones, complementos ni  sorpresas desagradables. Pruébalo hoy.',
            answers_here: 'Aquí las respuestas a tus dudas...',
            is_extension_free: '¿La extensión es gratuita?',
            is_extension_free_answer:
                'Sí, 100% gratuita y sin registro. Solo instala la extensión y empieza a ahorrar en tus tiendas favoritas.',
            how_install_extension: '¿Cómo instalar la extensión de Bchollos?',
            how_install_extension_answer:
                'Es muy fácil. Solo tienes que buscar Bchollos en tu el store de Google Chrome o Firefox e instalarla.',
            how_know_works: '¿Cómo puedo saber si funciona bien?',
            how_know_works_answer:
                'Cuando visites una de las tiendas asociadas automáticamente te mostraremos la extensión y empezarás a ver cupones. O si lo abres manualmente te dirá si hay ofertas en esa tienda en cuestión.',
            what_shops_discounts: '¿Qué tiendas tienen descuentos?',
            what_shops_discounts_answer:
                'Tenemos cupones & ofertas para las principales tiendas como Amazon, Rappi, Linio o Walmart, entre otras muchas.',
            how_remove: '¿Cómo se elimina la extensión?',
            how_remove_answer:
                'No te recomendamos desinstalarla porque perderás muchos ahorros en compras en línea. Pero si quieres hacerlo es clic derecho y seleccionar “Desinstalar”',
            works_mobile: '¿Funciona en móvil?',
            works_mobile_answer:
                'Estamos trabajando para que funcione en Safari. Te notificaremos por las Novevades del sitio.',
            title_seo: 'Extensión | Bchollos',
            description_seo: 'Página de extensión',
        },
        gratis: {
            products: 'Productos y servicios',
            free: 'GRATIS',
            in_mexico: 'en España',
            description: 'Descripción',
            featured_stores: 'Tiendas Populares',
            populars: 'Populares',
            news: 'Nuevos',
            home: 'Home',
            stores: 'Tiendas',
        },
        loginWithoutPassword: {
            login_without_password: 'Inicio de sesión sin contraseña',
            title_seo: 'Iniciar sin contraseña | Bchollos',
            description_seo: 'Página de inicio de sesión sin contraseña',
        },
        login: {
            login: 'Inicia sesión',
            no_account: '¿No tienes una cuenta?',
            register_here: 'Regístrate aquí',
        },
        notificaciones: {
            notifications: 'Notificaciones',
            no_readed: 'No leídas',
            no_unreaded: 'Por ahora no tienes notificaciones sin leer',
            read: 'Leídas',
            no_notifications: 'No hay notificaciones',
            no_notifications_by_now: 'Por ahora no tienes notificaciones',
            title_seo: 'Notificaciones | Bchollos',
            description_seo: 'Página para ver las notificaciones de tu cuenta',
            error: 'Hubo un error al cargar las notificaciones',
        },
        nuevaContrasena: {
            new_password: 'Nueva contraseña',
            write_new_password: 'Escribe tu nueva contraseña',
            repeat_new_password: 'Repetir contraseña',
            change_password: 'Cambiar contraseña',
            title_seo: 'Nueva contraseña | Bchollos',
            description_seo: 'Página de generación de nueva contraseña',
            close: 'Cerrar',
            incorrect_fields: 'Hay campos incorrectos, sigue las instrucciones.',
            token_failed: 'El token es incorrecto o ha caducado',
        },
        forgotPassword: {
            forgot_password: '¿Has olvidado tu contraseña?',
            title_seo: 'Olvidé mi contraseña | Bchollos',
            description_seo: 'Página de recuperación de contraseña',
            close: 'Cerrar',
            incorrect_fields: 'Hay campos incorrectos, sigue las instrucciones.',
            token_failed: 'El token es incorrecto',
        },
        registro: {
            register: 'Regístrate',
            have_account: '¿Tienes una cuenta?',
            login_here: 'Inicia sesión aquí',
        },
        search: {
            results: 'Resultados para',
            this: 'este',
            sorry: 'Lo siento! No hemos encontrado resultados en tu búsqueda',
            meanwhile: 'Mientras puedes revisar los mejores cupones aquí',
            what_are_looking: '¿Qué estás buscando?',
            easier: 'Facilita tu búsqueda eligiendo una de estas opciones',
            related: 'relacionadas con tu búsqueda',
            offer_and_coupons: 'Ofertas y cupones',
            featured: 'destacados',
            recommend: 'También te recomendamos estas marcas donde encontraras las mejores Ofertas y Cupones',
            results_for: 'Resultados de búsqueda para',
            of: 'de',
            all: 'Todo',
            offers: 'Ofertas',
            coupons: 'Cupones',
            brands: 'Marcas',
        },
        verifyAccount: {
            request_failed: 'Su solicitud no puede ser procesada correctamente',
            redirect: 'Serás redirigido a inicio en segundos:',
            verified_success: 'Cuenta verificada exitosamente',
            enjoy: 'Ahora disfruta de las ofertas/cupones en Bchollos',
            go_home: 'Ir a inicio',
            go_profile: 'Ir a mi perfil',
            error_on_validate: 'No se pudo verificar su cuenta',
            code_not_valid: 'Código de verificación no válido o expirado',
            go_to_settings: 'Ir a ajustes',
            contact_support: 'Contactar el soporte',
            verify_error: 'Ocurrió un error al intentar verificar tu cuenta',
            title_seo: 'Verificar cuenta | Bchollos',
            description_seo: 'Página para la verificación de la cuenta de usuario',
        },
    },
}

export default ES
